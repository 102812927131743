import React from "react";
import { Link, useStaticQuery, query } from "gatsby";
import InterestedPersonSignUp from "./InterestedPersonSignUp";
import "./SharingSection.css";

const SharingSection = ({ warmup }) => {
  return (
    <div className="SharingSection-container">
      <div className="SharingSection-container-inner">
        <div className="SharingSection-your-warmup-container">
          <h2>Share your favorite warm up</h2>
          <p>Do you have a warmup that you and your team love? Submit it and we'll add it to our library</p>
          <Link to={"submit-warmup"} className="Global-secondary-submit">Share Warm Up</Link>
        </div>
        <div className="SharingSection-working-work-container">
          <h2>We're working to make work better</h2>
          <p>We're building tools to make work better. If you want to stay up to date on what we're working on, sign up below.</p>
          <InterestedPersonSignUp />
        </div>
      </div>
    </div>
  )
}

export default SharingSection;
