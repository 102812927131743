import React from "react";
import { useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "./BeforeAfter.css";

const BeforeAfter = ({ warmup }) => {

  const query = useStaticQuery(graphql`
    query {
      before: file(relativePath: { eq: "meer-before.png" }) {
        ...meerkatImage
      }
      after: file(relativePath: { eq: "meer-after.png" }) {
        ...meerkatImage
      }
    }
  `);

  return (
    <div className="BeforeAfter-container">
      <div className="BeforeAfter-container-inner">
        <h1 className="BeforeAfter-header">
          Get your meetings flowing
        </h1>
        <h2 className="BeforeAfter-subheader">
          We've got a big ol' stack of quick meeting warm ups to get any group in sync.
        </h2>
        <div className="BeforeAfter-image-container">
          <div className="BeforeAfter-single-image">
            <div className="BeforeAfter-bubble" style={{ width: "140px", left: "-15px", top: 20, textAlign: "left"}}>I can write at least 7 emails during this</div>
            <div className="BeforeAfter-bubble" style={{ width: "90px", left: "28%", top: 75, textAlign: "left"}}>ZZZZZZ</div>
            <div className="BeforeAfter-bubble" style={{ width: "170px", left: "40%", top: -10, textAlign: "left"}}>Wait. This is an hour long?</div>
            <div className="BeforeAfter-bubble" style={{ width: "140px", right: "-15px", top: 50, textAlign: "left"}}>I hope we have pizza for lunch.</div>

            <Img style={{ width: "100%" }} fluid={query.before.childImageSharp.fluid} />
          </div>
          <div className="BeforeAfter-arrow-right"><FontAwesomeIcon icon="arrow-right" /></div>
          <div className="BeforeAfter-arrow-down"><FontAwesomeIcon icon="arrow-down" /></div>
          <div className="BeforeAfter-single-image">
            <div className="BeforeAfter-bubble" style={{ width: "50%", left: "25%", top: 20, textAlign: "center", fontSize: 24 }}>Let's Do This!</div>
            <Img style={{ width: "100%" }} fluid={query.after.childImageSharp.fluid} />
          </div>
        </div>
      </div>
    </div>
  )
}

export const meerkatImage = graphql`
  fragment meerkatImage on File {
    childImageSharp {
      fluid(maxWidth: 1000, maxHeight: 524) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export default BeforeAfter
