import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { Location } from '@reach/router';

import Layout from "../components/layout"
import Image from "../components/image"
import WarmupHome from "../components/warmups/WarmupHome"
import BeforeAfter from "../components/splash/BeforeAfter"
import WeirdPics from "../components/splash/WeirdPics"
import Checklist from "../components/splash/Checklist"
import WhyWarmUp from "../components/splash/WhyWarmUp"
import SharingSection from "../components/splash/SharingSection"

import SEO from "../components/seo"

const IndexPage = () => {
  const [showHeaderCTA, setShowHeaderCTA] = useState(false);

  useEffect(() => {
    const onScroll = e => {
      let mainCTA = document.getElementById("main-warmup-selector");
      if (mainCTA) {
        setShowHeaderCTA(mainCTA.getBoundingClientRect().top < 0)
      }
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [showHeaderCTA]);

  return (
    <Location>
    {
      locationProps =>
        <Layout hideWarmupButton={!showHeaderCTA}>
          <SEO title="Warmupz" />
          <WarmupHome locationProps={locationProps}/>
          <BeforeAfter />
          <Checklist />
          <SharingSection />
          <WeirdPics isHome={true} />
        </Layout>
    }
    </Location>
  );
}

export default IndexPage
