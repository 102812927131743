import React from "react";
import "./Checklist.css";

const Checklist = ({ warmup }) => {
  return (
    <div className="Checklist-container">
      <div className="Checklist-container-inner">
        <div className="Checklist-option">
          <div className="Checklist-option-title">
            Quick
          </div>
          <div className="Checklist-option-description">
            Two or three minutes is all it takes to get your meeting started right.
          </div>
        </div>
        <div className="Checklist-option">
          <div className="Checklist-option-title">
            Fresh
          </div>
          <div className="Checklist-option-description">
            Our library of is always growing to keep your meetings fresh.
          </div>
        </div>
        <div className="Checklist-option">
          <div className="Checklist-option-title">
            Remote Friendly
          </div>
          <div className="Checklist-option-description">
            Designed for in-person teams as well as those around the world.
          </div>
        </div>
        <div className="Checklist-option">
          <div className="Checklist-option-title">
            Fun
          </div>
          <div className="Checklist-option-description">
            Probably the most fun you'll have all day, or your money back.
          </div>
        </div>
      </div>
    </div>
  )
}

export default Checklist;
