import React, { useState } from "react";
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import SuccessText from "../SuccessText";
import "./InterestedPersonSignUp.css";

const ADD_INTERESTED_PERSON = gql`
  mutation AddInterestedPerson($email: String) {
    addInterestedPerson(input: {email: $email}) {
      interestedPerson {
        id
      }
    }
  }
`;

const InterestedPersonSignUp = () => {
  const [email, setEmail] = useState("");
  const [isSubmitted, setIsSubmitted] = useState("");

  const [addInterestedPerson, { loading: mutationLoading, error: mutationError, data }] = useMutation(ADD_INTERESTED_PERSON, {
    onCompleted: (data) => {
      if (data.addInterestedPerson && data.addInterestedPerson.interestedPerson) {
        setIsSubmitted(true);
      };
    }
  });

  return (
      isSubmitted ?
      <SuccessText text="Thanks! We'll keep you in the loop." /> :
      <form onSubmit={e => e.preventDefault()} className="InterestedPersonSignUp-form">
        <input type="text" value={email} placeholder="Your Email" onChange={e => setEmail(e.target.value)} />
        <button
          className="Global-secondary-submit"
          onClick={() => {
            addInterestedPerson({variables: { email: email }});
          }}
        >
          Submit
        </button>
      </form>
  )
}

export default InterestedPersonSignUp;
